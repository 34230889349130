<script>
import { onBeforeUnmount, reactive, ref, watch } from '@vue/composition-api'

import { getEnterpriseListApi, getRobotListApi } from '@/plugins/api/modules/login'
import { deleteQuesApi, getQuesDetailApi, updateQuesDetailApi } from '@/plugins/api/modules/ques'
import { useUtils } from '@core/libs/i18n'

import store from '@/store'
import { useRouter } from '@core/utils'

export default {
  setup(props, { emit }) {
    const { t } = useUtils()

    const { router } = useRouter()

    const fileContent = ref('')

    const queryParams = reactive({
      enterprise: null,
      appId: null,
      fileInfo: null,
    })

    // #region 获取企业列表, 应用列表
    const enterpriseList = ref([])
    store.commit('app/TOGGLE_CONTENT_OVERLAY', true)
    function getEnterpriseList() {
      getEnterpriseListApi().then(res => {
        enterpriseList.value = res.data
        store.commit('app/TOGGLE_CONTENT_OVERLAY', false)
      })
    }
    getEnterpriseList()

    const robotList = ref([])
    function getRobotList(enterprise) {
      getRobotListApi(enterprise)
        .then(res => {
          console.log('11111111111', res)
          robotList.value = res.data
        })
        .catch(() => {
          robotList.value = []
        })
    }
    watch(
      () => queryParams.enterprise,
      value => {
        getRobotList(value)
      },
    )

    // #endregion
    onBeforeUnmount(() => {
      store.commit('app/TOGGLE_CONTENT_OVERLAY', false)
    })
    const uploadFile = event => {
      console.log('122file', event)
      const file = event
      if (file) {
        if (file.name.toLowerCase().indexOf('txt') !== -1) {
          const formData = new FormData()
          formData.append('file', file)
          store.commit('app/TOGGLE_CONTENT_OVERLAY', true)
          updateQuesDetailApi(formData, queryParams.appId)
            .then(res => {
              console.log('111', res)
              getQuesDetail()
            })
            .catch(() => {
              store.commit('app/TOGGLE_CONTENT_OVERLAY', false)
            })
        }
      }
    }

    function getQuesDetail() {
      queryParams.fileInfo = null
      fileContent.value = ''
      getQuesDetailApi({ app_uid: queryParams.appId })
        .then(res => {
          if (res.data.length > 0) {
            queryParams.fileInfo = [{ name: res.data[0].fileName }]
            fileContent.value = res.data[0].content
            store.commit('app/TOGGLE_CONTENT_OVERLAY', false)
          }
        })
        .catch(() => {
          store.commit('app/TOGGLE_CONTENT_OVERLAY', false)
        })
    }
    watch(
      () => queryParams.appId,
      value => {
        getQuesDetail()
      },
    )

    const delFile = value => {
      deleteQuesApi({ appId: queryParams.appId }).then(res => {
        console.log('delFIle', res)
        queryParams.fileInfo = null
        fileContent.value = ''
      })
      console.log('del', value)
    }

    return {
      robotList,
      enterpriseList,
      fileContent,
      queryParams,
      t,
      uploadFile,
      delFile,
    }
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-text>
        <VForm @submit.prevent="() => {}">
          <VRow>
            <!-- 👉 First Name -->
            <VCol
              cols="12"
              md="4"
            >
              <VRow
                cols="12"
                md="6"
              >
                <VCol
                  cols="12"
                >
                  <v-autocomplete
                    v-model="queryParams.enterprise"
                    :items="enterpriseList"
                    item-text="name"
                    item-value="id"
                    density="compact"
                    :label="t('task.enterprise')"
                    variant="outlined"
                  ></v-autocomplete>
                </VCol>
              </VRow>
              <VRow
                cols="12"
              >
                <VCol
                  cols="12"
                >
                  <v-autocomplete
                    v-model="queryParams.appId"
                    :items="robotList"
                    item-text="name"
                    item-value="appId"
                    density="compact"
                    :label="t('task.robot')"
                    variant="outlined"
                  ></v-autocomplete>
                </VCol>
              </VRow>
            </VCol>

            <!-- 👉 Remember me -->
            <VCol
              cols="12"
              :md="5"
              :offset="2"
            >
              <v-file-input
                :value="queryParams.fileInfo"
                :disabled="!queryParams.appId"
                label="Test set"
                density="compact"
                accept=".txt"
                style="height: 100%;"
                @change="uploadFile"
                @click:clear="delFile"
              ></v-file-input>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>{{ t('task.filePreview') }}</v-card-title>
      <v-card-text>
        <div
          style="white-space: break-spaces;"
          v-html="fileContent"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>
